import React from 'react';
import Sidebar from '../Sidebar';
import { auth } from '../../firebase';
import { SpinnerInfinity } from 'spinners-react';
import error from '../../img/error.jpg';
import '../../css/proyecto.css';
import Navbar from '../Navbar';
import TarjetaFase from './TarjetaFase';
import { Modal } from 'react-bootstrap';

class Proyecto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleSidebar: false,
            showContent: false,
            loading: true,
            hideGoToTopBtn: true,
            dataProy: [],
            porcentaje: '',
            showModalImg: false,
            urlImgModal: '',
            dataPagos: []
        }
    }

    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        })
    }

    componentDidMount = () => {
        document.title = 'Garteel Clientes';
        auth.onAuthStateChanged( async (user) => {
            if (user) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'selectProyectoCorreo', correo: user.email, idProyecto: this.props.idProyecto })
                  };
                const response = await fetch('https://clientes.garteel.com/php/DB/proyectos.php', requestOptions);
                const data = await response.json();
                this.setState({
                    showContent: data,
                    loading: false
                })
                if (data === true) {
                    const requestOptionsInversion = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ operacion: 'selectInversion', correo: user.email, idProyecto: this.props.idProyecto })
                      };
                    const responseInversion = await fetch('https://clientes.garteel.com/php/DB/inversionistas.php', requestOptionsInversion);
                    const dataInversion = await responseInversion.json();
                    const requestOptionsProy = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ operacion: 'selectWhere', idProyecto: this.props.idProyecto })
                      };
                    const responseProy = await fetch('https://clientes.garteel.com/php/DB/proyectos.php', requestOptionsProy);
                    const dataProy = await responseProy.json();
                    var fases = dataProy[0].fases;
                    var porcentajesFases = 0;
                    fases.forEach(element => {
                        porcentajesFases += Number(element.porcentaje);
                    });
                    var porcentaje = (porcentajesFases/fases.length).toFixed(0);
                    this.setState({
                        dataProy: dataProy[0],
                        porcentaje: porcentaje,
                        inversion: dataInversion.inversion
                    })
                    document.title = this.state.dataProy.nombre + ' - Garteel Clientes';
                    this.calcularPagos();
                }
            }
        })
    }

    numberWithCommas(x) {
        if (x === 0) {
            return '0.00';
        }else{
            x = Number(x).toFixed(2);
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    handleScroll = (e) => {
        if ((e.target.scrollTop >= 400 && window.innerWidth <= 768) || (e.target.scrollTop >= 500 && window.innerWidth > 768)) {
            this.setState({
                hideGoToTopBtn: false
            })
        }else{
            this.setState({
                hideGoToTopBtn: true
            })
        }
    }

    goToTop = () => {
        document.getElementById('main').scrollTop = 0;
    }

    barraProgreso = () => {
        var porcentaje = this.state.porcentaje;
        var gradient = '';
        if (porcentaje < 50) {
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + porcentaje + '%)';
        }else{
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + (100 - Number(porcentaje)) + '%)';
        }
        return gradient
    }

    porcentaje = () => {
        var porcentaje = this.state.porcentaje;
        if (porcentaje < 6) {
            return porcentaje + '%'
        }else{
            return (Number(porcentaje) - 3) + '%'   
        }
    }

    openModalImg = (urlImg) => {
        this.setState({
            showModalImg: true,
            urlImgModal: urlImg
        })
    }

    hideModalImg = () => {
        this.setState({
            showModalImg: false,
            urlImg: ''
        })
    }

    rowEstado = () => {
        let fecha = this.state.dataProy.fechaLimiteInversion;
        let pagoRendimientos = this.state.dataProy.pagoRendimientos;
        let diasFaltantes = this.diasFaltantes(fecha);
        let mesesTranscurridos = this.getMonthsBetween(fecha);
        let plazosTranscurridos = 0;
        if (pagoRendimientos === 'Mensual') {
            plazosTranscurridos = mesesTranscurridos/1;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Trimestral') {
            plazosTranscurridos = mesesTranscurridos/3;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Cuatrimestral') {
            plazosTranscurridos = mesesTranscurridos/4;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Semestral') {
            plazosTranscurridos = mesesTranscurridos/6;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Anual') {
            plazosTranscurridos = mesesTranscurridos/12;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        let fechaDisplay = new Date(fecha + 'T00:00:00');
        fechaDisplay = fechaDisplay.toLocaleString('es-MX',{dateStyle: 'short'});
        if (diasFaltantes > 0) {
            return <div className="row" >
                        <div className="col-12" >
                            <p className='m-0'>Fecha de inicio de proyecto: {fechaDisplay}</p>
                        </div>
                        <div className="col-12" >
                            <p >Faltan {diasFaltantes} día(s) para empezar a generar rendimientos.</p>
                        </div>
                    </div>
        }else{
            return <div className="row">
                        <div className="col-12" >
                            <p className='mb-0' >Fecha de inicio de proyecto: {fechaDisplay}</p>
                        </div>
                        <div className="col-12" >
                            <p className='mb-0' >Pago de rendimientos: {pagoRendimientos}</p>
                        </div>
                        <div className="col-12" >
                            <p className='mb-0' >Plazos cumplidos: {plazosTranscurridos}</p>
                        </div>
                        <div className="col-12" >
                            <p >Generado: ${this.numberWithCommas(this.montoPlazo() * plazosTranscurridos)}</p>
                        </div>
                    </div>
        }
    }

    diasFaltantes = (fecha) => {
        let fechaActual = new Date();
        let fechaLimite = new Date(fecha + ' 00:00');
        var one_day=1000*60*60*24;
        return Math.ceil((fechaLimite.getTime()-fechaActual.getTime())/(one_day));
    }

    getMonthsBetween(fecha,roundUpFractionalMonths){
        let date1 = new Date();
        let date2 = new Date(fecha + ' 00:00');
        //Months will be calculated between start and end dates.
        //Make sure start date is less than end date.
        //But remember if the difference should be negative.
        var startDate=date1;
        var endDate=date2;
        var inverse=false;
        if(date1>date2)
        {
            startDate=date2;
            endDate=date1;
            inverse=true;
        }

        //Calculate the differences between the start and end dates
        var yearsDifference=endDate.getFullYear()-startDate.getFullYear();
        var monthsDifference=endDate.getMonth()-startDate.getMonth();
        var daysDifference=endDate.getDate()-startDate.getDate();

        var monthCorrection=0;
        //If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
        //The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
        if(roundUpFractionalMonths===true && daysDifference>0)
        {
            monthCorrection=1;
        }
        //If the day difference between the 2 months is negative, the last month is not a whole month.
        else if(roundUpFractionalMonths!==true && daysDifference<0)
        {
            monthCorrection=-1;
        }

        return (inverse?-1:1)*(yearsDifference*12+monthsDifference+monthCorrection);
    }

    montoPlazo = () => {
        let montoPlazo = 0;
        let rendimientosGarantizada = (this.state.inversion * (this.state.dataProy.tirGarantizado/100));
        if (this.state.dataProy.pagoRendimientos === 'Mensual') {
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*12);
        }
        if (this.state.dataProy.pagoRendimientos === 'Trimestral') {
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*4);
        }
        if (this.state.dataProy.pagoRendimientos === 'Cuatrimestral') {
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*3);
        }
        if (this.state.dataProy.pagoRendimientos === 'Semestral') {
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*2);
        }
        if (this.state.dataProy.pagoRendimientos === 'Anual') {
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*1);
        }
        return montoPlazo.toFixed(2)
    }

    calcularPagos = () => {
        let plazos = 0; 
        let montoPlazo = 0;
        let rendimientos = (this.state.inversion * (this.state.dataProy.tirGarantizado))/100;
        let rendimientosGarantizada = (this.state.inversion * (this.state.dataProy.tirGarantizado/100));
        let totalRecibido = Number(this.state.inversion) + rendimientos;
        let pagoFinal = 0;
        let noTarjetas = 0;
        let mesesASumar = 0;
        if (this.state.dataProy.pagoRendimientos === 'Mensual') {
            let anos = this.state.dataProy.plazo;
            plazos = anos * 12;
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*12);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
            noTarjetas = this.getPlazos(plazos);
            mesesASumar = 1;
        }
        if (this.state.dataProy.pagoRendimientos === 'Trimestral') {
            let anos = this.state.dataProy.plazo;
            plazos = anos * 4;
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*4);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
            noTarjetas = this.getPlazos(plazos);
            mesesASumar = 3;
        }
        if (this.state.dataProy.pagoRendimientos === 'Cuatrimestral') {
            let anos = this.state.dataProy.plazo;
            plazos = anos * 3;
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*3);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
            noTarjetas = this.getPlazos(plazos);
            mesesASumar = 4;
        }
        if (this.state.dataProy.pagoRendimientos === 'Semestral') {
            let anos = this.state.dataProy.plazo;
            plazos = anos * 2;
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*2);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
            noTarjetas = this.getPlazos(plazos);
            mesesASumar = 6;
        }
        if (this.state.dataProy.pagoRendimientos === 'Anual') {
            let anos = this.state.dataProy.plazo;
            plazos = anos * 1;
            montoPlazo = rendimientosGarantizada/(this.state.dataProy.plazo*1);
            pagoFinal = totalRecibido - (montoPlazo * this.getPlazos(plazos));
            noTarjetas = this.getPlazos(plazos);
            mesesASumar = 12;
        }
        var dataPagos = [];
        var date = new Date(this.state.dataProy.fechaLimiteInversion + 'T00:00:00');
        for (let index = 0; index < noTarjetas; index++) {
            date = new Date(this.state.dataProy.fechaLimiteInversion + 'T00:00:00');
            var newDate = new Date(date.setMonth(date.getMonth()+(mesesASumar*(index + 1))));
            dataPagos[index] = {
                'fecha': newDate,
                'montoPlazo': montoPlazo.toFixed(2),
                'monto': (montoPlazo * (index + 1)).toFixed(2),
                'yaPaso': new Date() >= newDate
            }
        }
        date = new Date(this.state.dataProy.fechaLimiteInversion + 'T00:00:00');
        newDate = new Date(date.setMonth(date.getMonth()+(this.state.dataProy.plazo * 12)));
        dataPagos[noTarjetas] = {
            'fecha': newDate,
            'montoPlazo': pagoFinal,
            'monto': Number((montoPlazo * (noTarjetas + 1))) + Number(this.state.inversion),
            'yaPaso': new Date() >= newDate
        }
        this.setState({
            dataPagos: dataPagos
        })
    }

    getPlazos = (plazos) => {
        if (Number.isInteger(plazos)) {  
            return plazos - 1
        }else{
            return Math.floor(plazos)
        }
    }

    isLeapYear = function (year) { 
        return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
    };
    
    getDaysInMonth = function (year, month) {
        return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    };
    
    addMonths = function (value) {
        var n = this.getDate();
        this.setDate(1);
        this.setMonth(this.getMonth() + value);
        this.setDate(Math.min(n, this.getDaysInMonth()));
        return this;
    };

    render() { 
        return ( 
            <>
            <div className='app'>
                <Sidebar toggledSidebar={this.state.toggleSidebar} toggleSidebar={this.toggleSidebar}/>
                <main onScroll={this.handleScroll} id='main'>
                    <div className="container-fluid h-100 proyecto">
                        <Navbar toggleSidebar={this.toggleSidebar}/>
                        <div className='py-4 px-3 h-100'>
                            {
                            this.state.loading ?
                                <div className='d-flex justify-content-center align-items-center h-100'>
                                    <SpinnerInfinity size={150} thickness={70} speed={150} color="rgba(33, 37, 41, 1)" secondaryColor="rgba(46, 191, 112, 1)"/>
                                </div>
                            :
                            this.state.showContent ?
                            <>
                                <div className="row bg-dark p-2" style={{ borderRadius: '5px' }}>
                                    <h1 className='text-center' style={{ color: 'white' }}>{this.state.dataProy.nombre}</h1>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6 col-xl-3 col-xxl-2">
                                        { this.state.dataProy.imgP && 
                                            <img src={"https://www.garteel.com/img/proyectos/" + this.state.dataProy.imgP} alt="imgProyecto" width={'100%'} style={{ borderRadius: '5px' }}/>
                                        }
                                    </div>
                                    <div className="col-lg-6 d-none d-lg-block d-xl-none">
                                        <h6>Documentos</h6>
                                        {this.state.dataProy.docs && this.state.dataProy.docs.map( (doc, index) => 
                                            <div key={index} className="row">
                                                <div className="col-12">
                                                    <a href={'https://www.garteel.com/docs/proyectos/' + doc} target='_blank' rel="noreferrer" style={{ color: '#2EBF70' }}>{doc}</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-xl-6 mt-3 mt-xl-0">
                                        <h6>Descripción</h6>
                                        <div style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: this.state.dataProy.descripcion }}/>
                                    </div>
                                    <div className="col-xl-3 col-xxl-4 d-lg-none d-xl-block mt-3 mt-xl-0">
                                        <h6>Documentos</h6>
                                        {this.state.dataProy.docs && this.state.dataProy.docs.map( (doc, index) => 
                                            <div key={index} className="row">
                                                <div className="col-12">
                                                    <a href={'https://www.garteel.com/docs/proyectos/' + doc} target='_blank' rel="noreferrer" style={{ color: '#2EBF70' }}>{doc}</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <hr />
                                    <div className="col-12 text-center">
                                        <h2>Finanzas</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 py-2 px-4">
                                        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '5px' }} className='p-3 h-100'> 
                                            <div className="row" >
                                                <div className="col-6" >
                                                    <p className='mb-0' style={{  }} >Inversión:</p>
                                                </div>
                                                <div className="col-6 text-end" >
                                                    <p className='mb-0' style={{  }} >${this.numberWithCommas(this.state.inversion)}</p>
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-6" >
                                                    <p className='mb-0' style={{  }} >Ganancia:</p>
                                                </div>
                                                <div className="col-6 text-end" >
                                                    <p className='mb-0' style={{  }} >${this.numberWithCommas(this.state.inversion*(this.state.dataProy.tirGarantizado/100))}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row" >
                                                <div className="col-6" >
                                                    <p className='mb-1' style={{  }} >Total:*</p>
                                                </div>
                                                <div className="col-6 text-end" >
                                                    <p className='mb-1' style={{  }} >${this.numberWithCommas(Number(this.state.inversion*(this.state.dataProy.tirGarantizado/100)) + Number(this.state.inversion))}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 py-2 px-4">
                                        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '5px' }} className='p-3 h-100'>
                                            {this.rowEstado()}
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    {this.state.dataPagos && this.state.dataPagos.map((e, index) =>
                                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mt-4 d-flex justify-content-center" key={index}>
                                            <div className="" style={{ height: '160px', width: '160px', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                                                <div style={{ height: '100px' }} className='row d-flex justify-content-center align-items-center'>
                                                    <div className="col-12 text-center">
                                                        <p className='m-0'>{e.fecha.getDate()}</p>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <h5 className='m-0'>{e.fecha.toLocaleString('default', { month: 'long' }).toUpperCase()}</h5>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <h5 className='m-0'>{e.fecha.getFullYear()}</h5>
                                                    </div>
                                                </div>
                                                <div style={{ height: '30px', backgroundColor: '#e8e9ea' }} className='d-flex justify-content-center align-items-center'>
                                                    <h6 className='m-0'>+ ${this.numberWithCommas(e.montoPlazo)}</h6>
                                                </div>
                                                <div style={{ height: '30px', backgroundColor: e.yaPaso ? '#2EBF70' : '#212529', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', color: 'white' }} className='d-flex justify-content-center align-items-center'>
                                                    <h6 className='m-0'>= ${this.numberWithCommas(e.monto)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <p className='text-center mt-3'>Calculado con el plazo máximo y rendimientos garantizados.</p>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 text-center">
                                        <hr />
                                        <h2>Construcción</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className='mb-0'>Avance de la construcción:</h5>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className='' style={{ width: '100%', height: '40px', paddingTop: '7px', paddingBottom: '7px', paddingLeft: '1px', paddingRight: '1px', background: this.barraProgreso(), border: '2px solid #212529', borderRadius: '5px' }}>
                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje() }}>
                                                {this.state.porcentaje + '%'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center tarjetasFases">
                                    {this.state.dataProy.fases && this.state.dataProy.fases.map((fase, index) => 
                                        <TarjetaFase data={fase} key={index} openModalImg={this.openModalImg}/>
                                    )}
                                </div>
                                <Modal show={this.state.showModalImg} centered fullscreen onHide={this.hideModalImg}>
                                    <Modal.Header closeButton style={{ border: 'none' }} className='bg-dark' closeVariant='white'>

                                    </Modal.Header>
                                    <Modal.Body className='d-flex bg-dark'>
                                        <img src={this.state.urlImgModal} alt="img" className='imgModal'/>
                                    </Modal.Body>
                                </Modal>

                                <button className='float' hidden={this.state.hideGoToTopBtn} onClick={this.goToTop}><i className="bi bi-caret-up"></i></button>
                            </>
                            :
                            <>
                                <div className='containerError'>
                                    <div className=''>
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <img src={error} alt="" className='imgError'/>
                                            </div>
                                        </div>
                                        <div className='row text-center'>
                                            <div className="col-12">
                                                <h1>¡Ups! Parece que no tienes acceso a esto.</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </main>
            </div> 
            </>
         );
    }
}
 
export default Proyecto;