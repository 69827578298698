import React, { Component } from 'react';
import { Card, CardActionArea, CardContent, Typography, CardMedia } from '@mui/material';

class Construccion extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() { 
        return (
            <div className="row m-0 mt-4 pb-4 animate__animated animate__fadeInUp">
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-12 text-center">
                            <h4 className='m-0' style={{ color: 'gray', fontFamily: 'Julius' }}>Avance semanal</h4>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='h-100' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                                <div className='py-2' style={{backgroundColor: '#2EBF70', color: 'white', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                    <p className='text-center m-0 tituloActividadesPorSemana d-sm-none' style={{ fontFamily: 'Julius' }}>Act. realizadas semana 07 - 12 Oct.</p>
                                    <p className='text-center m-0 tituloActividadesPorSemana d-none d-sm-block' style={{ fontFamily: 'Julius' }}>Actividades realizadas semana 07 - 12 Octubre</p>
                                </div>
                                <div className='w-100 px-2 py-1'>
                                    <ol>
                                        <li>Termino de emboquillado de ventanas y puertas corredizas.</li>
                                        <li>Chaflan y entortado de azotea.</li>
                                        <li>Habilitado de trabe y castillos para muro de fachada y levantamiento de muro al igual que aplanados.</li>
                                        <li>Retiro de escombro de escaleras.</li>
                                        <li>Termino de demolición de domos y pretiles.</li>
                                        <li>Termino de demolición de zapatas y aplanado de4 las mismas,</li>
                                        <li>Alimentaciones de agua para baños.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className='h-100' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                                <div className='py-2' style={{backgroundColor: '#2EBF70', color: 'white', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                    <p className='text-center m-0 tituloActividadesPorSemana d-sm-none' style={{ fontFamily: 'Julius' }}>Act. a realizar semana 14 - 19 Oct.</p>
                                    <p className='text-center m-0 tituloActividadesPorSemana d-none d-sm-block' style={{ fontFamily: 'Julius' }}>Actividades a realizar semana 14 - 19 Octubre</p>
                                </div>
                                <div className='w-100 px-2 py-1'>
                                    <ol>
                                        <li>Firme de concreto de baños de segundo nivel.</li>
                                        <li>Termino de estuco en muro perimetral.</li>
                                        <li>Habilitado de castillos y trabes y levantamiento de muro colindante.</li>
                                        <li>Modificación de cableado en domo.</li>
                                        <li>Retiro de escombro de la obra.</li>
                                        <li>Termino de alimentación de línea de agua y prueba de funcionamiento.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 mt-5">
                    <div className="col-12 text-center">
                            <h4 className='m-0' style={{ color: 'gray', fontFamily: 'Julius' }}>Reporte Fotográfico de la Semana</h4>
                        </div>
                    </div>
                    <div className="row m-0 mt-2 d-flex justify-content-center">
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/rf-1.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                <img src={this.props.rutaImagen("/img/proyectos/37/rf-1.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/rf-2.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                <img src={this.props.rutaImagen("/img/proyectos/37/rf-2.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 mt-3 mt-sm-3 mt-md-0 col-xl-2">
                            <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/rf-3.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                <img src={this.props.rutaImagen("/img/proyectos/37/rf-3.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-0 col-xl-2">
                            <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/rf-4.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                <img src={this.props.rutaImagen("/img/proyectos/37/rf-4.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-0 col-xl-2">
                            <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/rf-5.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                <img src={this.props.rutaImagen("/img/proyectos/37/rf-5.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-0 col-xl-2">
                            <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/rf-6.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                <img src={this.props.rutaImagen("/img/proyectos/37/rf-6.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 mt-5">
                        <div className="col-12">
                            <p className='mb-0' style={{ fontFamily: 'Julius' }}>Avance de la construcción:</p>
                        </div>
                    </div>
                    <div className="row m-0 mt-1">
                        <div className="col-12">
                            <div className='' style={{ width: '100%', height: '40px', paddingTop: '7px', paddingBottom: '7px', paddingLeft: '1px', paddingRight: '1px', background: this.props.barraProgreso(this.props.state.porcentaje), border: '2px solid #212529', borderRadius: '5px' }}>
                                <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.props.porcentaje(this.props.state.porcentaje) }}>
                                    {this.props.state.porcentaje}%
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 d-flex justify-content-center tarjetasFases">
                        <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                            <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                <CardActionArea style={{ height: '100%' }}  onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/37-preliminares.jpg"))}>
                                    <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                        <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                            Preliminares
                                        </Typography>
                                    </CardContent>
                                        <CardMedia
                                            component="img"
                                            width="100"
                                            image={this.props.rutaImagen("/img/proyectos/37/37-preliminares.jpg")}
                                            alt="imgFase"
                                            className='imagenTarjeta'
                                        />
                                    <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                        <div className="row p-0">
                                            <div className="col-12 p-0">
                                                <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.props.barraProgreso(100)}}>
                                                    <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.props.porcentaje(91)}}>
                                                        100%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                            <div className="col-12">
                                                <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                <p style={{ fontSize: '12px', textAlign: 'justify' }}></p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                            <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                <CardActionArea style={{ height: '100%' }} onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/37-albanileria.jpg"))}>
                                    <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                        <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                            Albañilerías
                                        </Typography>
                                    </CardContent>
                                        <CardMedia
                                            component="img"
                                            width="100"
                                            image={this.props.rutaImagen("/img/proyectos/37/37-albanileria.jpg")}
                                            alt="imgFase"
                                            className='imagenTarjeta'
                                        />
                                    <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                        <div className="row p-0">
                                            <div className="col-12 p-0">
                                                <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.props.barraProgreso(88) }}>
                                                    <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.props.porcentaje(88) }}>
                                                        88%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                            <div className="col-12">
                                                <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                            <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                <CardActionArea style={{ height: '100%' }} onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/37-acabados.jpg"))}>
                                    <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                        <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                            Acabados
                                        </Typography>
                                    </CardContent>
                                        <CardMedia
                                            component="img"
                                            width="100"
                                            image={this.props.rutaImagen("/img/proyectos/37/37-acabados.jpg")}
                                            alt="imgFase"
                                            className='imagenTarjeta'
                                        />
                                    <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                        <div className="row p-0">
                                            <div className="col-12 p-0">
                                                <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.props.barraProgreso(47) }}>
                                                    <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.props.porcentaje(47) }}>
                                                        47%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                            <div className="col-12">
                                                <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                            <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                <CardActionArea style={{ height: '100%' }} onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/37-electrica.jpg"))}>
                                    <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                        <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                            Instalación eléctrica
                                        </Typography>
                                    </CardContent>
                                        <CardMedia
                                            component="img"
                                            width="100"
                                            image={this.props.rutaImagen("/img/proyectos/37/37-electrica.jpg")}
                                            alt="imgFase"
                                            className='imagenTarjeta'
                                        />
                                    <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                        <div className="row p-0">
                                            <div className="col-12 p-0">
                                                <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.props.barraProgreso(73) }}>
                                                    <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.props.porcentaje(73) }}>
                                                        73%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                            <div className="col-12">
                                                <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                            <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                <CardActionArea style={{ height: '100%' }} onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/37-hidrosanitaria.jpg"))}>
                                    <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                        <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                            Instalación hidrosanitaria
                                        </Typography>
                                    </CardContent>
                                        <CardMedia
                                            component="img"
                                            width="100"
                                            image={this.props.rutaImagen("/img/proyectos/37/37-hidrosanitaria.jpg")}
                                            alt="imgFase"
                                            className='imagenTarjeta'
                                        />
                                    <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                        <div className="row p-0">
                                            <div className="col-12 p-0">
                                                <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.props.barraProgreso(45)}}>
                                                    <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.props.porcentaje(45)}}>
                                                        45%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                            <div className="col-12">
                                                <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                            <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                <CardActionArea style={{ height: '100%' }} onClick={() => this.props.openModalImg(this.props.rutaImagen("/img/proyectos/37/37-carpinteria.jpg"))}>
                                    <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                        <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                            Carpintería
                                        </Typography>
                                    </CardContent>
                                        <CardMedia
                                            component="img"
                                            width="100"
                                            image={this.props.rutaImagen("/img/proyectos/37/37-carpinteria.jpg")}
                                            alt="imgFase"
                                            className='imagenTarjeta'
                                        />
                                    <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                        <div className="row p-0">
                                            <div className="col-12 p-0">
                                                <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.props.barraProgreso(50) }}>
                                                    <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.props.porcentaje(50) }}>
                                                        50%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                            <div className="col-12">
                                                <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                            <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                <CardActionArea style={{ height: '100%' }}>
                                    <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                        <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                            Canecelería y herrería
                                        </Typography>
                                    </CardContent>
                                        <CardMedia
                                            component="img"
                                            width="100"
                                            image={"/img/sinImagen.jpg"}
                                            alt="imgFase"
                                            className='imagenTarjeta'
                                        />
                                    <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                        <div className="row p-0">
                                            <div className="col-12 p-0">
                                                <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.props.barraProgreso(0) }}>
                                                    <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.props.porcentaje(0) }}>
                                                        0%
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                            <div className="col-12">
                                                <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Construccion;