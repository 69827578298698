import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Inicio from './Inicio/Inicio';
import Login from './Inicio/Login';
import { PrivateRoute } from './PrivateRoute';
import { LoginRoute } from './LoginRoute';
import NoTienesCuenta from './Inicio/NoTienesCuenta';
import Cuenta from './Cuenta/Cuenta';
import Proyecto from './Proyectos/Proyecto';
import Ayuda from './Ayuda/Ayuda';
import ProyectosConstruccion from './ProyectosConstruccion/ProyectosConstruccion';
import MinaTrinidad from './ProyectosConstruccion/MinaTrinidad';
import anamSedena from './ProyectosConstruccion/anamSedena';
import MinaMoran from './ProyectosConstruccion/MinaMoran';
import DepartamentosVillas from './ProyectosConstruccion/DepartamentosVillas';
import LoftVillas from './ProyectosConstruccion/LoftVillas';
import CasaBautistaVital from './ProyectosConstruccion/CasaBautistaVital';

class Routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            linksProyectos: [],
            proyectosConstruccion: []
         }
    }

    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectLinks' })
          };
        const responseProy = await fetch('https://clientes.garteel.com/php/DB/proyectos.php', requestOptions);
        const dataProy = await responseProy.json();
        this.setState({
            linksProyectos: dataProy
        })
    }

    render() { 
        return ( 
            <Switch>
                <LoginRoute exact path='/login' component={Login}/>
                <LoginRoute exact path='/notienescuenta' component={NoTienesCuenta}/>
                <PrivateRoute exact path="/" component={ProyectosConstruccion}/>
                <PrivateRoute exact path="/cuenta" component={Cuenta}/>
                <PrivateRoute exact path="/inversiones" component={Inicio}/>
                <PrivateRoute exact path="/ayuda" component={Ayuda}/>
                {this.state.linksProyectos[0] && this.state.linksProyectos.map((e, index) => 
                    <PrivateRoute key={index} exact path={'/' + e.idProyecto + '-' + e.nombreLink} component={(props) => <Proyecto {...props} idProyecto={e.idProyecto}/>}/>
                )}
                <PrivateRoute exact path="/proyecto/33" component={MinaTrinidad}/>
                <PrivateRoute exact path="/proyecto/34" component={anamSedena}/>
                <PrivateRoute exact path="/proyecto/35" component={LoftVillas}/>
                <PrivateRoute exact path="/proyecto/36" component={DepartamentosVillas}/>
                <PrivateRoute exact path="/proyecto/37" component={MinaMoran}/>
                <PrivateRoute exact path="/proyecto/38" component={CasaBautistaVital}/>
                <Route exact path="*" component={() => <Redirect to={'/'}/>}/>
            </Switch>
         );
    }
}
 
export default Routes;