import React, { Component } from 'react'

class Pagos extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleDescargarClick = (ruta, nombre) => {
        // Genera un timestamp único para evitar la caché
        const timestamp = new Date().getTime();

        // Agrega el timestamp como parámetro a la URL
        const url = `${ruta}?timestamp=${timestamp}`;
        console.log(url);
        

        const username = 'GarteelClientes'; // Reemplaza con tu nombre de usuario
        const password = 'Kmj8CL6z'; // Reemplaza con tu contraseña

        // Codifica las credenciales en Base64
        const base64Credentials = btoa(`${username}:${password}`);

        // Crea una solicitud de descarga
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);

        // Configura las credenciales en la solicitud
        xhr.setRequestHeader('Authorization', `Basic ${base64Credentials}`);

        // Maneja la respuesta de la solicitud
        xhr.onload = function () {
            if (xhr.status === 200) {
            // La descarga se realizó correctamente, puedes procesar el archivo aquí
            const blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = nombre; // Nombre del archivo que se descargará
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            } else {
            console.error('Error al descargar el archivo. Código de estado:', xhr.status);
            }
        };

        // Envia la solicitud
        xhr.responseType = 'blob';
        xhr.send();
    };

    render() { 
        return (
            <div className="row m-0 mt-3 pb-5 d-flex justify-content-center animate__animated animate__fadeInUp">
                <div className="col-md-10 col-lg-10 col-xl-9 col-xxl-8 text-center">
                    <table className='table table-hover mb-0' style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Total Estimación:</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>$333,881.91</th>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <button style={{ border: 'none', backgroundColor: 'transparent', fontSize: '16px', textDecoration: 'underline' }} onClick={() => this.handleDescargarClick("/docs/proyectos/37-Estimacion.xlsx", "Estimacion.xlsx")}>Descargar Estimación Detallada</button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="col-md-10 col-lg-10 col-xl-9 col-xxl-8 text-center mt-5">
                    <table className='table table-hover mb-0' style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>No.</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Monto</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Fecha</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Estatus</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Recibo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>1</td>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$200,000.00</td>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>04/09/2024</td>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.props.handleDescargarClick("/docs/proyectos/37-Recibo-1.pdf", "Recibo_1.pdf")}>Descargar</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
 
export default Pagos;